import * as React from 'react';
import {ReactNode} from 'react';
import {Box} from '@mui/material';
import Footer from '../Footer';
import Header from '../Header';
import 'lightgallery/css/lightgallery.css';
import 'lightgallery/css/lg-zoom.css';
import {graphql, useStaticQuery} from 'gatsby';
import {CMSData} from '../../@types/CMSData';

export default function MainPageLayout({children}: {children: ReactNode}) {
  const data: CMSData = useStaticQuery<CMSData>(graphql`query MainLayoutQuery {
                          allContactInfo(filter: {id: {eq: "main-contact-info"}}) {
                            nodes {
                              address
                              city
                              email
                              facebookUrl
                              facebookName
                              instagramUrl
                              phone
                              workingHours
                            }
                          }
                        }
                      `);
  return <main>
    <Box sx={{
      minHeight: '100vh',
      display: 'flex',
      flexDirection: 'column',
    }}>
      <Box sx={{flex: '1 1 auto'}}>
        <Header contactInfo={data.allContactInfo.nodes[0]} />
        {children}
      </Box>
      <Footer />
    </Box>
  </main>;
}
