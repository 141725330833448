import {createTheme} from '@mui/material';

import {TypographyOptions} from '@mui/material/styles/createTypography';

declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    menuItem: true;
    boldLink: true;
  }
}

interface ExtendedTypographyOptions extends TypographyOptions {
  menuItem: React.CSSProperties;
  boldLink: React.CSSProperties;
}

const globalFontFamily = [
  'Poppins',
  'BlinkMacSystemFont',
  '"Segoe UI"',
  'Roboto',
  '"Helvetica Neue"',
  'Arial',
  'sans-serif',
  '"Apple Color Emoji"',
  '"Segoe UI Emoji"',
  '"Segoe UI Symbol"',
].join(',');

const theme = createTheme({
  palette: {
    text: {
      primary: '#222',
    },
    primary: {
      light: '#747474',
      main: '#4a4a4a',
      dark: '#222222',
    },
    secondary: {
      light: '#80d6ff',
      main: '#42a5f5',
      dark: '#0077c2',
    },
  },
  components: {
    MuiTypography: {
      styleOverrides: {
        gutterBottom: {
          marginBottom: 20,
        },
      },
    },
  },
  typography: {
    menuItem: {
      fontFamily: globalFontFamily,
      fontWeight: 300,
      fontSize: '1.5rem',
      textDecoration: 'none',
    },
    caption: {
      fontSize: '0.8rem',
    },
    h1: {
      color: '#222',
      fontSize: '4.7rem',
      fontWeight: 800,
    },
    h2: {
      color: '#222',
      fontSize: '2.7rem',
      fontWeight: 700,
    },
    h4: {
      color: '#222',
      fontSize: '2.6rem',
      fontWeight: 700,
    },
    body1: {
      color: '#222',
      fontSize: '1.3rem',
      fontWeight: 400,
    },
    body2: {
      color: '#222',
      fontSize: '1.2rem',
      fontWeight: 400,
    },
    fontFamily: globalFontFamily,
    fontSize: 14,
  } as ExtendedTypographyOptions,
});

theme.typography = {
  ...theme.typography,
  h1: {
    ...theme.typography.h1,
    [theme.breakpoints.down('lg')]: {
      fontSize: '4rem',
    },
    [theme.breakpoints.down('md')]: {
      fontSize: '3rem',
    },
  },
  h2: {
    ...theme.typography.h2,
    [theme.breakpoints.down('lg')]: {
      fontSize: '2.5rem',
    },
    [theme.breakpoints.down('md')]: {
      fontSize: '2rem',
    },
  },
  h4: {
    ...theme.typography.h4,
    [theme.breakpoints.down('lg')]: {
      fontSize: '2.2rem',
    },
    [theme.breakpoints.down('md')]: {
      fontSize: '1.6rem',
    },
  },
  body1: {
    ...theme.typography.body1
    ,
    [theme.breakpoints.down('lg')]: {
      fontSize: '1.2rem',
    }, [theme.breakpoints.down('md')]:
      {
        fontSize: '1.1rem',
      }
    ,
  },
}
;

export default theme;
