import React, {ReactElement} from 'react';
import {Link} from 'gatsby';
import {styled, Typography} from '@mui/material';

function MainMenuLink({to, text, children}: {to: string, text?: string, children?: ReactElement}) {
  const StyledLink = styled(Link)(({theme}) => ({
    textDecoration: 'none',
    color: 'inherit',
    ':hover': {
      transition: '0.2s',
      color: theme.palette.text.secondary,
    },
  }));
  return (
    <StyledLink to={to}>
      {children ? children :
        <Typography variant={'menuItem'} color='inherit' style={{textDecoration: 'none'}}>{text}</Typography>}
    </StyledLink>
  );
}

export default MainMenuLink;
