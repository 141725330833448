import * as React from 'react';
import {useState} from 'react';
import MainPageLayout from './src/components/layout/MainPageLayout';
import {GatsbyBrowser} from 'gatsby';
import {MainMenuContext} from './src/context/MainMenuContext';


export const wrapPageElement: GatsbyBrowser['wrapPageElement'] = ({element}) => {
  const [isMenuOpened, setIsMenuOpened] = useState(false);

  return <MainMenuContext.Provider value={{isMenuOpened, setIsMenuOpened}}>
    <MainPageLayout>
      {element}
    </MainPageLayout>
  </MainMenuContext.Provider>;
};
