import {Box, Container, Grid, Typography, useTheme} from '@mui/material';
import * as React from 'react';
import FlexibilityValueSvg from '../assets/value-flexibility.svg';
import HandmadeValueSvg from '../assets/value-handmade.svg';
import QualityValueSvg from '../assets/value-quality.svg';
import TraditionValueSvg from '../assets/value-tradition.svg';
import ValueBadge from './ValueBadge';

export default function Footer() {
  const theme = useTheme();
  return (
    <Box sx={{backgroundColor: theme.palette.primary.dark, p: '20px 5px', mt: '50px'}}>
      <Container maxWidth={'md'}>
        <Grid container justifyContent={'space-around'} alignItems={'stretch'} direction={'column'} spacing={4}>
          <Grid item alignSelf={'center'} sx={{mt: 3}}>
            <Typography variant={'h5'} fontWeight={'bold'} color={theme.palette.background.paper}>Naše vrednote</Typography>
          </Grid>
          <Grid item>
            <Grid container justifyContent={'space-around'} spacing={3}>
              <Grid item xs={6} md={3}>
                <ValueBadge backgroundImage={FlexibilityValueSvg} title={'Prilagodljivost'} />
              </Grid>
              <Grid item xs={6} md={3}>
                <ValueBadge backgroundImage={HandmadeValueSvg} title={'Ročna izdelava'} />
              </Grid>
              <Grid item xs={6} md={3}>
                <ValueBadge backgroundImage={QualityValueSvg} title={'Kvaliteta'} />
              </Grid>
              <Grid item xs={6} md={3}>
                <ValueBadge backgroundImage={TraditionValueSvg} title={'Tradicija'} />
              </Grid>
            </Grid>
          </Grid>
          <Grid item alignSelf={'center'}>
            <Typography variant={'caption'} color={theme.palette.primary.light}>
              © {new Date().getFullYear()} Žvan Custom Campers
            </Typography>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
}
