import {Box, Grid, Typography, useTheme} from '@mui/material';
import * as React from 'react';

export default function ValueBadge({backgroundImage, title}: {backgroundImage: string, title: string}) {
  const theme = useTheme();
  const textColor = theme.palette.background.paper;
  return (<Grid container direction={'column'} alignItems={'stretch'}>
    <Grid item>
      <Box sx={{
        height: '150px',
        width: 'auto',
        backgroundImage: `url('${backgroundImage}')`,
        backgroundSize: 'contain',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
      }}>
      </Box>
    </Grid>
    <Grid item alignSelf={'center'}>
      <Typography variant={'body1'} fontWeight={600} color={textColor}>{title}</Typography>
    </Grid>
  </Grid>);
}
