import {
  Box,
  Collapse,
  Container,
  Grid,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  styled,
  Typography,
  useTheme,
} from '@mui/material';
import {Link} from 'gatsby';
import * as React from 'react';
import {ReactNode, useContext} from 'react';
import logoSrc from '../assets/logo.svg';
import useMobile from './useMobile';
import {Fade as Hamburger} from 'hamburger-react';
import MainMenuLink from './MainMenuLink';
import {MainMenuContext} from '../context/MainMenuContext';
import CamperVanImage from '../assets/camper-van.jpg';
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import EmailIcon from '@mui/icons-material/Email';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import HomeIcon from '@mui/icons-material/Home';
import {ContactInfo} from '../@types/CMSData';
import useSocialName from '../hooks/useSocialName';
import {useFormatPhone} from '../hooks/useFormatPhone';

function SocialIcon(props: {icon: ReactNode, text: string, href?: string, noNewTab?: boolean}) {
  const theme = useTheme();

  return <ListItem
    component={'a'}
    href={props.href ? props.href : undefined}
    target={props.noNewTab ? '' : '_blank'}>
    <ListItemIcon
      sx={{color: theme.palette.background.paper, minWidth: '30px', mr: '10px'}}>{props.icon}</ListItemIcon>
    <ListItemText
      primary={
        <Typography variant={'body2'} color={theme.palette.background.paper} fontWeight={700}>
          {props.text}
        </Typography>
      }
    />
  </ListItem>;
}

export default function Header({contactInfo}: {contactInfo: ContactInfo}) {
  const isMobile = useMobile();
  const {isMenuOpened, setIsMenuOpened} = useContext(MainMenuContext);
  const theme = useTheme();
  const formattedPhone = useFormatPhone(contactInfo.phone);
  const socialFacebookName = contactInfo.facebookName;
  const socialInstagramName = useSocialName(contactInfo.instagramUrl);
  const mapsUrl = `https://maps.google.com/maps?q=${encodeURIComponent(contactInfo.address + ', ' + contactInfo.city)}`;

  const ContactContainer = styled('div')(({theme}) => ({
    background: `url(${CamperVanImage})`,
    backgroundSize: 'cover',
    backgroundPosition: '80% 30%',
    width: '80vw',
    height: '100%',
    overflow: 'hidden',
    display: 'flex',
    alignItems: 'center',
  }));
  const ZvanLogo = styled('div')(() => ({
    width: 'auto',
    height: isMobile ? '70px' : '100px',
    display: 'block',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    backgroundSize: 'contain',
    margin: `${isMobile ? '30px' : '50px'} 5px 20px 5px`,
    backgroundImage: `url("${logoSrc}")`,
  }));

  return (
    <>
      <Container maxWidth={'xl'}>
        <Grid container direction={'row'} spacing={2}>
          <Grid item xs={isMobile ? 12 : 3} sx={{pb: 5}}>
            {isMobile && (
              <Box sx={{float: 'right', userSelect: 'none'}}>
                <Hamburger rounded toggled={isMenuOpened} toggle={() => setIsMenuOpened(!isMenuOpened)} />
              </Box>
            )}
            <Link to={'/'}><ZvanLogo /></Link>
            <Collapse in={!isMobile || (isMobile && isMenuOpened)} collapsedSize={0}>
              <Grid container direction={'column'} alignItems={'center'} spacing={2} sx={{p: 2}}>
                <Grid item>
                  <MainMenuLink to='/'><HomeIcon /></MainMenuLink>
                </Grid>
                <Grid item>
                  <MainMenuLink to='/storitve#vse' text={'STORITVE'} />
                </Grid>
                <Grid item>
                  <MainMenuLink to='/projekti#vsi' text={'PROJEKTI'} />
                </Grid>
                <Grid item>
                  <MainMenuLink to='/#kontakt' text={'KONTAKT'} />
                </Grid>
                {isMobile && (
                  <Grid item>
                    <Box sx={{
                      display: 'flex',
                      gap: '20px',
                      width: '100%',
                    }}>
                      <a href={contactInfo.facebookUrl} target={'_blank'}><FacebookIcon
                        sx={{color: theme.palette.text.primary}} /></a>
                      <a href={contactInfo.instagramUrl} target={'_blank'}><InstagramIcon
                        sx={{color: theme.palette.text.primary}} /></a>
                      <a href={`tel:${contactInfo.phone}`} target={'_blank'}><LocalPhoneIcon
                        sx={{color: theme.palette.text.primary}} /></a>
                      <a href={`mailto:${contactInfo.email}`} target={'_blank'}><EmailIcon
                        sx={{color: theme.palette.text.primary}} /></a>
                    </Box>
                  </Grid>
                )}
              </Grid>
            </Collapse>
          </Grid>
          {!isMobile && (
            <Grid item xs={9}>
              <ContactContainer>
                <List sx={{
                  display: 'inline-block',
                  marginLeft: '10px',
                  borderRadius: '10px',
                }}>
                  <SocialIcon icon={<FacebookIcon />} text={socialFacebookName}
                              href={contactInfo.facebookUrl} />
                  <SocialIcon icon={<InstagramIcon />} text={socialInstagramName}
                              href={contactInfo.instagramUrl} />
                  <SocialIcon icon={<EmailIcon />} text={contactInfo.email}
                              href={`mailto:${contactInfo.email}`} />
                  <SocialIcon icon={<LocalPhoneIcon />} text={formattedPhone}
                              href={`tel:${contactInfo.phone}`}
                              noNewTab={true} />
                  <SocialIcon icon={<LocationOnIcon />} text={`${contactInfo.address}, ${contactInfo.city}`}
                              href={mapsUrl} />
                  <SocialIcon icon={<AccessTimeIcon />} text={contactInfo.workingHours} noNewTab={true} />
                </List>
              </ContactContainer>
            </Grid>
          )}
        </Grid>
      </Container></>
  );
}
